/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/fullpage.js@2.9.7/dist/jquery.fullpage.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/sticky.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/turbolinks@5.1.1/dist/turbolinks.min.js
 * - /npm/scrollreveal@3.4.0/dist/scrollreveal.min.js
 * - /npm/jquery.marquee@1.5.0/jquery.marquee.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
